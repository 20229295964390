import React from "react";
import Icon from "../Icon";

export default ({title, icon}) => {
    return (
        <div className={"sidebar-menu-item"}>
            <div className={"sidebar-menu-item__icon"}>
                <Icon icon={icon} />    
            </div>    
            <div className={"sidebar-menu-item__title"}>{title}</div>
        </div>
    )
}