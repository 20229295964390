import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import Header from "@/components/Header";
import Container from "@/components/Container";
import ListNote from "@/components/ListNote";
import Layout from "@/components/Layout";
import Sidebar from "@/components/Sidebar";

const IndexPage = ({ data, pageContext }) => {
  const { edges } = data.allMdx;

  useEffect(() => {
    console.log(window);
  }, [])

  return (
    <Layout>
      <Sidebar />
      <div className="listnotes">
        <ListNote edges={edges} />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query PortfolioTemplateQuery {
    allMdx(
      filter: {
        frontmatter: { publish: { ne: false } }
        fileAbsolutePath: { regex: "/" }
      }
      limit: 10
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          ...postList
        }
      }
    }
  }
`