import React from "react";



export default ({children}): IMenuItems => {
    return (
        <div className={"sidebar-menu"}>
            {children}
        </div>
    )
}
