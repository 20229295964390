import React from "react";
import Logo from "@/components/Logo"
import SidebarMenuTags from "@/components/SidebarMenuTags";
import Icon from "../Icon";

export default () => {
    return (
        <div className="sidebar">
            <div className="sidebar__top">
                <Logo />
                <SidebarMenuTags />
            </div>

            <div className="sidebar__bottom">
                <div className="sidebar__mailprompt">
                    <p>GET IN TOUCH:</p>
                    <div className="sidebar__mailprompt__address">
                        <Icon icon={"mail"} />
                        <p><a href="mailto:faiz@mm.st">faiz@mm.st`</a></p>
                    </div>
                </div>
            </div>

        </div>
    )
}