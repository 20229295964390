import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SidebarMenu from '../SidebarMenu';
import SidebarMenuItem from '../SidebarMenuItem';

const SidebarMenuTags = ({ data }) => {
    return (
        <StaticQuery
        query={graphql`
        query TagsQuery {
            allMdx {
                group(field: frontmatter___tags) {
                    tag: fieldValue
                    totalCount
                }
            }
        }  
        `}
        render={data => (
            <SidebarMenu>
                {data?.allMdx?.group.map((tag, idx) => 
                    <SidebarMenuItem
                        title={tag.tag}
                        icon={tag.tag.replace(' ', '').replace('/', '')}
                    />
                )}
            </SidebarMenu>
        )}
      />
        
    )
}

export default SidebarMenuTags;
